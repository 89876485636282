/* You can add global styles to this file, and also import other style files */

@import url('https://fonts.googleapis.com/css2?family=Spartan:wght@100;200;300;400;500;600;700;800;900&display=swap');

html,
body {
  margin: 0 !important;
  padding: 0 !important;
  height: 100%;
  overflow-y: hidden;
}
#root {
  height: 100%;
}
* {
  letter-spacing: normal;
  line-height: 1.5;
}
/* * {
	font-family: 'Roboto', sans-serif;
	box-sizing: border-box;
}
*/
* {
  font-family: 'Spartan', sans-serif;
  box-sizing: border-box;
}
a {
  text-decoration: none;
  color: inherit;
}
p a {
  color: #00a0e9;
  &:hover {
    color: #00a0e9;
    text-decoration: underline;
  }
}
.MuiTabs-indicator {
  height: 4px !important;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
code {
  font-family: monospace;
  font-size: 0.75rem;
  background-color: #353535;
  padding: 7px;
  display: block;
}

.wxc-meeting-info__title {
  display: none;
}

.wxc-meeting-info {
  //display: none;
  &:after {
    display: inline;
    font-size: 2rem;
  }
}
.wxc-button--join .wxc-meeting-control__button-text {
  display: none;
}

.MuiChip-root .MuiChip-label {
  transform: translateY(3px);
  font-size: 0.8rem;
  font-weight: 600;
}

.MuiTooltip-popper .MuiTooltip-tooltip {
  //padding-top: 10px;
  font-size: 0.75rem;
  line-height: 1;
  padding: 8px 8px 5px 8px;
}

.MuiTabs-root .MuiTab-root {
  font-weight: 600;
  text-transform: none;
  font-size: 0.9rem;
  &.Mui-selected {
    font-weight: 700;
  }
}

.MuiButton-root {
  text-transform: none !important;
}

.MuiFormControlLabel-label {
  transform: translateY(2px);
}

.MuiTextField-root input {
  transform: translateY(4px);
  font-size: 1rem;
}
